import React, { useState } from "react";
import { Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { PrimaryFormattedButton } from "../../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../../Component/Button/SecondaryFormattedButton";
import "../SendToRenewalModal.scss";
import { DatePickerComponent } from "../../../../../Component/DatePicker/DatePickerComponent";
import { SingleSelect } from "../../../../../Component/Select/SingleSelect";
import { Option } from "../../../../../Component/Select/SelectTypes";
import "./EnterExamCertificateModal.scss";
import { AttachmentContainer } from "../../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import { API, getBaseUrl } from "../../../../../api";
import { AlertMessage } from "../../../../../Alert/AlertMessage";
import { AlertType } from "../../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../../Alert/alertActions";
import {
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI,
  Application,
  ExamInfo,
  FileObjectType,
  FileReference
} from "../../../../../../api_client/medre_api";
import { saveFilesWithAlerts } from "../../../../../Component/FileDropzone/UploadFile";
import { displayAlert } from "../../../../../Util/AlertUtil";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  applicationId: string;
  certificates: FileReference[];
  fileUploaded: (file: FileReference[]) => void;
  fileDeleted: (minioFileName: string) => void;
  updateApplication: (application: Application) => void;
  type: FileObjectType;
}

enum SchoolEnum {
  UT = "Tartu Ülikool",
  TTK = "Tartu Tervishoiu Kõrgkool",
  TALTK = "Tallinna Tervishoiu Kõrgkool"
}

interface FormInput {
  diplomaNumber?: string;
  diplomaDate?: Date;
  diplomaPlace?: Option | null;
}

export const EnterExamCertificateModal = ({
  isOpen,
  onClose,
  applicationId,
  certificates,
  fileDeleted,
  fileUploaded,
  type,
  updateApplication
}: Props) => {
  const defaultValues: FormInput = {
    diplomaPlace: null
  };

  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    errors,
    reset,
    formState,
    control,
    setValue
  } = useForm<FormInput>({
    defaultValues,
    mode: "onBlur"
  });
  const [selectedDate, setSelectedDate] = useState();
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const { touched, isSubmitted, isSubmitSuccessful } = formState;

  const onSubmit = (data: FormInput) => {
    if (certificates.length > 0) {
      const { diplomaPlace, diplomaNumber } = data;
      officialAppDataAPI(undefined, getBaseUrl(), API)
        .addExamInfo(
          applicationId,
          {
            diplomaNumber,
            diplomaDate: selectedDate,
            diplomaPlace: diplomaPlace?.value,
            type
          } as ExamInfo,
          {
            withCredentials: true
          }
        )
        .then((response) => {
          const alertMessage = <AlertMessage id="statusUpdateSuccess" />;
          const alert = {
            id: 0,
            type: AlertType.Success,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
          updateApplication(response.data);
          handleClose();
        })
        .catch(() => {
          const alertMessage = <AlertMessage id="statusUpdateFailure" />;
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        });
    }
  };

  const handleClose = () => {
    setIsPopOverOpen(false);
    setSelectedDate(undefined);
    reset(defaultValues);
    onClose();
  };

  const handleOptionChange = (newOption: Option) => {
    setValue("diplomaPlace", newOption, { shouldValidate: true });
  };

  const handleSaveFiles = (files: File[]) => {
    saveFilesWithAlerts(files, type, applicationId, dispatch, fileUploaded);
  };

  const handleFileDelete = (fileName: string) => {
    let toDelete = certificates?.filter(
      (file) => file.fileName === fileName
    )[0];
    if (toDelete && toDelete.minioFileName) {
      officialAppDataAPI(undefined, getBaseUrl(), API)
        .deleteOfficialApplicationFile(applicationId, toDelete.fileObjectType!, toDelete.minioFileName, {
          withCredentials: true
        })
        .then(() => {
          fileDeleted(toDelete!.minioFileName!);
          displayAlert("fileDeleteSuccessful", AlertType.Info, dispatch);
        })
        .catch(() => displayAlert("fileDeleteFailure", AlertType.Danger, dispatch));
    }
  };

  const getModalTitle = () =>
    type === FileObjectType.ProceedingAptitudeTestCert ? (
      <FormattedMessage
        id="EnterAptitudeTestCertificateModal.header"
        defaultMessage="Sobivustesti tulemus"
      />
    ) : (
      <FormattedMessage
        id="EnterExamCertificateModal.header"
        defaultMessage="Vastavuseksami tulemus"
      />
    );

  return (
    <Modal className="enter-exam-certificate-modal" isOpen={isOpen}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader tag="h4">{getModalTitle()}</ModalHeader>
        <ModalBody>
          <AttachmentContainer
            fileReferences={certificates}
            isActive={isPopOverOpen}
            onClick={() => setIsPopOverOpen(!isPopOverOpen)}
            type={type}
            maxFilesCount={1}
            saveFiles={handleSaveFiles}
            handleFileDelete={handleFileDelete}
          />
          <div className="d-flex">
            <label>Tunnistuse number</label>
            <Input
              name="diplomaNumber"
              valid={
                ((isSubmitted && !isSubmitSuccessful) ||
                  touched.diplomaNumber) &&
                !errors.diplomaNumber
              }
              invalid={!!errors.diplomaNumber}
              innerRef={register({ required: true })}
            />
          </div>
          <div className="d-flex">
            <label>Sooritamise kp</label>
            <DatePickerComponent
              onDateChange={setSelectedDate}
              selectedDate={selectedDate}
              name="diplomaDate"
              valid={(touched.diplomaDate || isSubmitted) && selectedDate}
              invalid={(touched.diplomaDate || isSubmitted) && !selectedDate}
              ref={register({ required: true })}
            />
          </div>
          <div className="d-flex">
            <label>Teostamise koht</label>
            <Controller
              name="diplomaPlace"
              className=""
              as={SingleSelect}
              options={[
                { value: SchoolEnum.UT, label: SchoolEnum.UT },
                { value: SchoolEnum.TTK, label: SchoolEnum.TTK },
                { value: SchoolEnum.TALTK, label: SchoolEnum.TALTK }
              ]}
              isValid={
                isSubmitted && !isSubmitSuccessful && !errors.diplomaPlace
              }
              isInValid={!!errors.diplomaPlace}
              handleOptionChange={handleOptionChange}
              control={control}
              rules={{ required: true }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="backToApplication"
            onClick={handleClose}
          />
          <PrimaryFormattedButton id="confirmResult" />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
