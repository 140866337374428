import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { ActivityLicenseDocuments } from "../Documents/ActivityLicenseDocuments";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { licenseAcquisitionActions } from "../../../../ActivityLicense/licenseAcquisitionActions";
import { FileDeleteType, FileObjectType, FileReference, LicenseAcquisition } from "../../../../../api_client/medre_api";

export const LicenseAcquisitionRequiredFiles = [
  {
    fileObjectType:
      FileObjectType.LicenseAcquisitionEconomicActivityConfirmation
  },
  {
    fileObjectType:
      FileObjectType.LicenseAcquisitionPropertyTransitionConfirmation
  },
  {
    fileObjectType:
      FileObjectType.LicenseAcquisitionHealthcareProvidingConfirmation
  },
  {
    fileObjectType: FileObjectType.LicenseAcquisitionPreviousOwnerAgreement
  }
];

export const LicenseAcquisitionOptionalFiles = [
  {
    fileObjectType: FileObjectType.LicenseAcquisitionPersonnelList
  }
];

export const LicenseAcquisitionDocumentsContainer = () => {
  const dispatch = useDispatch();

  const fileReferences = useSelector(
    (state: RootState) =>
      state.licenseAcquisitionApplication.fileReferences || [],
    shallowEqual
  );
  const draftId = useSelector(
    (state: RootState) => state.licenseAcquisitionApplication.id
  );

  const setSaveAllowed = useCallback(
    (isAllowed: boolean) => {
      dispatch(
        licenseAcquisitionActions.updateApplicationDraft(
          "saveAllowed" as keyof LicenseAcquisition,
          isAllowed
        )
      );
    },
    [dispatch]
  );

  const onUploadSuccess = (files: FileReference[]) => {
    dispatch(licenseAcquisitionActions.addFileReferences(files));
  };

  const handleFileDeletion = (minioFileName: string) => {
    dispatch(licenseAcquisitionActions.removeFileReference(minioFileName));
  }

  const documents = (
    <FormattedMessage
      id="activityLicense.documents"
      defaultMessage="Dokumendid"
    />
  );

  return (
    <div className="activity-license-documents-wrapper">
      <h3 className="application-title fw-normal">{documents}</h3>
      <p>
        <FormattedMessage
          id="activityLicense.addDocuments"
          defaultMessage="Lisa taotlusele dokumendid ja klõpsa “Edasi”."
        />
      </p>
      <h4 className="application-title fw-normal">{documents}</h4>
      <ActivityLicenseDocuments
        hasOther={false}
        requiredFiles={LicenseAcquisitionRequiredFiles}
        optionalFiles={LicenseAcquisitionOptionalFiles}
        fileReferences={fileReferences}
        objectId={draftId!}
        setSaveAllowed={setSaveAllowed}
        onUploadSuccess={onUploadSuccess}
        deleteType={FileDeleteType.LicenseAcquisition}
        handleFileDeletion={handleFileDeletion}
      />
    </div>
  );
};
