import React, { useCallback, useEffect, useState } from "react";
import { Button, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getBaseUrl, API } from "../../../../api";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import {
  formatDate,
  renderOverViewRow
} from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { formatProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import { CompanyInfoRow } from "./CompanyInfoRow";
import { ActivityLocationsRow } from "../../../Shared/ActivityLicense/Locations/ActivityLocationsRow";
import { FilesRow } from "./FilesRow";
import { FormattedButton } from "../../../../Component/Button/FormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { StateFeeFM } from "../../../../Messages/StateFeeFM";
import { StateFeeInfoModal } from "../../../Shared/StateFee/StateFeeInfoModal";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import { ApplicationDeleteConfirmationModal } from "../../Application/Table/ApplicationDeleteConfirmationModal";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { RenewalModal } from "../Renewal/RenewalModal";
import { Loader } from "../../../../Component/Loader/Loader";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { DecisionsRow } from "./DecisionsRow";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import {
  ActivityLicenseApplication,
  ActivityLicenseApplicationStatus,
  FileReference,
  TegevuslubadeTaotlusteTeenusApiFactory as activityLicenseApplicationAPI,
  TegevuslubadeTeenusApiFactory as activityPermitAPI,
  ActivityLicenseApplicationProceedingTypeEnum,
  FileObjectType,
  ActivityLicenseBusinessAreaBusinessAreaTypeEnum,
  FileTemplate, FileDeleteType
} from "../../../../../api_client/medre_api";
import { displayAlert } from "../../../../Util/AlertUtil";
import {specialistBusinessAreaId} from "../../../Shared/ActivityLicense/Locations/LocationsUtil";

interface Props {
  isOpen?: boolean;
  applicationId?: string;
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum;
  handleDelete: () => void;
  reloadApplications: () => void;
}

export const ActivityLicenseApplicationAccordion = ({
  isOpen,
  applicationId,
  proceedingType,
  handleDelete,
  reloadApplications
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [activityLicenseApplication, setActivityLicenseApplication] =
    useState<ActivityLicenseApplication>();

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [stateFeeModalIsOpen, setStateFeeModalIsOpen] = useState(false);
  const [renewalModalIsOpen, setRenewalModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isApplicantInfoRowOpen, setIsApplicantInfoRowOpen] = useState(false);
  const [isActivityLocationsRowOpen, setIsActivityLocationsRowOpen] =
    useState(false);
  const [isFilesRowOpen, setIsFilesRowOpen] = useState(false);
  const [isDecisionsRowOpen, setIsDecisionsRowOpen] = useState(false);

  const submittingPerson =
    activityLicenseApplication?.submittingPersonFirstName +
    " " +
    activityLicenseApplication?.submittingPersonLastName;

  const currentStatus = activityLicenseApplication?.currentStatus;
  const notSubmitted =
    currentStatus?.status === ActivityLicenseApplicationStatus.Saved;
  const isProceedingReopened =
    currentStatus?.status ===
    ActivityLicenseApplicationStatus.ProceedingReopened;

  const loadActivityLicense = useCallback(() => {
    setIsLoading(true);
    activityLicenseApplicationAPI(undefined, getBaseUrl(), API)
      .getActivityLicenseApplication(applicationId as string, {
        withCredentials: true
      })
      .then((res) => setActivityLicenseApplication(res.data))
      .finally(() => setIsLoading(false));
  }, [applicationId]);

  useEffect(() => {
    if (isOpen) {
      loadActivityLicense();
    }
  }, [isOpen, loadActivityLicense]);

  const proceedWithDraft = () => {
    if (activityLicenseApplication!.hasOwnProperty("activityLicense")) {
      delete activityLicenseApplication!.activityLicense?.locations;
    }

    dispatch(
      activityLicenseApplicationActions.setActivityLicense(
        activityLicenseApplication!
      )
    );
    if (
      activityLicenseApplication?.proceedingType ===
      ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation
    ) {
      history.push("/new-activity-location");
      return;
    } else if (
      activityLicenseApplication?.proceedingType ===
      ActivityLicenseApplicationProceedingTypeEnum.Service
    ) {
      history.push(`/new-activity-license-service`);
      return;
    }
    history.push("/new-activity-license");
  };

  const handleApplicationDelete = () => {
    activityLicenseApplicationAPI(undefined, getBaseUrl(), API)
      .deleteActivityLicenseApplication1(applicationId as string, {
        withCredentials: true
      })
      .then(() => {
        handleDelete();
        displayAlert(
          "applicationDraftDeleteSuccess",
          AlertType.Success,
          dispatch
        );
      })
      .catch(() => {
        displayAlert(
          "applicationDraftDeleteFailure",
          AlertType.Danger,
          dispatch
        );
      })
      .finally(() => setDeleteModalIsOpen(false));
  };

  const handleContinueProceeding = () => {
    activityLicenseApplicationAPI(undefined, getBaseUrl(), API)
      .continueApplicationProceeding3(applicationId as string, {
        withCredentials: true
      })
      .then(() => {
        displayAlert("renewApplicationSuccess", AlertType.Success, dispatch);
        reloadApplications();
      })
      .catch(() =>
        displayAlert("renewApplicationFailure", AlertType.Danger, dispatch)
      );
  };

  const handleUploadSuccess = (files: FileReference[]) => {
    setActivityLicenseApplication({
      ...activityLicenseApplication,
      fileReferences: [
        ...(activityLicenseApplication?.fileReferences || []),
        ...files
      ]
    });
  };

  const handleDeleteSuccess = (fileName: string) => {
    setActivityLicenseApplication((prevState) => {
      const files = prevState?.fileReferences?.filter(
        (f) => f.minioFileName !== fileName
      );
      return {
        ...prevState,
        fileReferences: files
      };
    });
  };

  const isMobileLocation = () => {
    const adsAdrID = activityLicenseApplication?.locations![0].adsAdrID;
    return adsAdrID === "mobile";
  };

  const isSpecialistActivityLicenseApplication = () : boolean => {
    return activityLicenseApplication?.businessArea?.businessAreaType
        === ActivityLicenseBusinessAreaBusinessAreaTypeEnum.Specialist;
  }

  const getRequiredBusinessAreaFileTypesForMobile = (): Array<FileTemplate> => {
    const businessAreaRequirements = activityLicenseApplication?.businessArea?.businessAreaFileRequirements;

    let filteredBusinessAreaRequirements = businessAreaRequirements
      ?.filter(businessAreaRequirement => businessAreaRequirement.requiredForMobileLocation) ?? [];
    let fileTemplates = filteredBusinessAreaRequirements.map(requirement => requirement.fileTemplate);
    return fileTemplates || [];
  }

  const requiredFiles = () => {
    return isMobileLocation()
      ? getRequiredBusinessAreaFileTypesForMobile()
      : activityLicenseApplication?.businessArea?.requiredFiles || [];
  }

  const renderButtonRow = () => {
    const stateFee = activityLicenseApplication?.stateFee;
    if (currentStatus?.status === ActivityLicenseApplicationStatus.Saved) {
      return (
        <>
          <Row className="justify-content-md-end justify-content-center application-header-row mb-3 mt-3">
            <FormattedButton
              id="deleteApplication"
              onClick={() => setDeleteModalIsOpen(true)}
            />
            <PrimaryFormattedButton
              id="continueWithSavedDraft"
              onClick={proceedWithDraft}
            />
          </Row>
          <ApplicationDeleteConfirmationModal
            applicationNumber={activityLicenseApplication?.applicationNumber!}
            isOpen={deleteModalIsOpen}
            onClose={() => setDeleteModalIsOpen(false)}
            onDelete={handleApplicationDelete}
          />
        </>
      );
    }
    if (!notSubmitted && !stateFee?.paid) {
      return (
        <>
          <Row className="justify-content-md-end justify-content-center application-header-row mb-3 mt-3">
            <Button
              className="ml-1"
              onClick={() => setStateFeeModalIsOpen(true)}
            >
              <StateFeeFM id="stateFeeInstructionHeader" />
            </Button>
          </Row>
          <StateFeeInfoModal
            isOpen={stateFeeModalIsOpen}
            onClose={() => setStateFeeModalIsOpen(false)}
            deadline={stateFee?.stateFeeDeadline}
            referenceNumber={stateFee?.referenceNumber}
            stateFeeValue={stateFee?.stateFeeValue}
          />
        </>
      );
    }
    if (currentStatus?.status === ActivityLicenseApplicationStatus.Paused) {
      return (
        <>
          <Row className="justify-content-md-end justify-content-center application-header-row mb-3 mt-3">
            <PrimaryFormattedButton
              id="renewApplication"
              className="ml-1"
              onClick={() => setRenewalModalIsOpen(true)}
            />
          </Row>
          <RenewalModal
            isOpen={renewalModalIsOpen}
            onClose={() => setRenewalModalIsOpen(false)}
            applicationId={applicationId || ""}
            notes={activityLicenseApplication?.renewalNotes || ""}
            requiredFiles={requiredFiles()}
            optionalFile={!isSpecialistActivityLicenseApplication ? FileObjectType.ActivityLicensePersonnelAgreement : undefined}
            fileReferences={activityLicenseApplication?.fileReferences || []}
            onUploadSuccess={handleUploadSuccess}
            onDeleteSuccess={handleDeleteSuccess}
            renewApplication={handleContinueProceeding}
            deleteType={FileDeleteType.ActivityLicenseApplication}
          />
        </>
      );
    }
    return null;
  };

  const getStateFeeText = () => {
    if (activityLicenseApplication?.stateFee?.paid) {
      return <StateFeeFM id="paid" />;
    } else {
      return (
        <StateFeeFM
          id="notPaidWithButton"
          values={{
            button: (msg: string) => (
              <Button
                className="p-0 link-button"
                color="link"
                onClick={() => setStateFeeModalIsOpen(true)}
              >
                {msg}
              </Button>
            )
          }}
        />
      );
    }
  };

  const state = (
    <ActivityLicenseApplicationState
      currentStatus={activityLicenseApplication?.currentStatus?.status}
      isDetailed={false}
    />
  );

  if (isLoading) {
    return <Loader backdrop={true} />;
  }
  return (
    <tr className="detail-info">
      <td colSpan={7} className="pt-0 pb-0">
        {!isLoading && renderButtonRow()}
        <div className="detail-col pl-0 pr-0">
          <Row className="col-header">
            <GeneralFM id="applicationData" />
          </Row>
          {renderOverViewRow(
            "activityLicense",
            "proceedingType",
            formatProceedingType(proceedingType!)
          )}
          {renderOverViewRow(
            "activityLicense",
            "applicationNumber",
            activityLicenseApplication?.applicationNumber?.toString()
          )}
          {renderOverViewRow(
            "activityLicense",
            activityLicenseApplication?.currentStatus?.status ===
              ActivityLicenseApplicationStatus.Saved
              ? "savedDate"
              : "submissionDate",
            formatDate(activityLicenseApplication?.arrivalTime)
          )}
          {renderOverViewRow(
            "activityLicense",
            "businessArea",
            activityLicenseApplication?.businessArea?.name
          )}
          {activityLicenseApplication?.proceedingType !==
            ActivityLicenseApplicationProceedingTypeEnum.ActivityLicense &&
            renderOverViewRow(
              "activityLicense",
              "licenseNumber",
              activityLicenseApplication?.activityLicense?.number
            )}
          {renderOverViewRow("application", "state", state)}
          {!notSubmitted &&
            !activityLicenseApplication?.stateFee?.paid &&
            renderOverViewRow(
              "activityLicense",
              "stateFeeDeadline",
              formatDate(activityLicenseApplication?.stateFee?.stateFeeDeadline)
            )}
          {!notSubmitted && (
            <>
              {renderOverViewRow("stateFee", "stateFee", getStateFeeText())}
              {renderOverViewRow(
                "stateFee",
                "stateFeeSum",
                `${activityLicenseApplication?.stateFee?.stateFeeValue?.toString()} €`
              )}
            </>
          )}
          {renderOverViewRow(
            "activityLicense",
            "tisIntegration",
            <ActivityLicenseFM id={"tisIntegrationConfirmation"} />
          )}
        </div>
        <CompanyInfoRow
          isOpen={isApplicantInfoRowOpen}
          handleClick={() => setIsApplicantInfoRowOpen(!isApplicantInfoRowOpen)}
          company={activityLicenseApplication?.company}
          submittingPerson={submittingPerson}
          submittingPersonIdCode={
            activityLicenseApplication?.submittingPersonIdCode
          }
        />
        {activityLicenseApplication?.locations && (
          <ActivityLocationsRow
            isOpen={isActivityLocationsRowOpen}
            isActivityApplicationLicense={true}
            handleClick={() =>
              setIsActivityLocationsRowOpen(!isActivityLocationsRowOpen)
            }
            specialistBusinessAreaId={specialistBusinessAreaId(activityLicenseApplication)}
            activityLocations={activityLicenseApplication?.locations}
            isHospital={activityLicenseApplication?.businessArea?.isHospital!}
            addTHTEndpoint={
              isProceedingReopened
                ? activityLicenseApplicationAPI(undefined, getBaseUrl(), API)
                    .addEmployees2
                : undefined
            }
            removeEmployeeEndpoint={
              isProceedingReopened
                ? activityLicenseApplicationAPI(undefined, getBaseUrl(), API)
                    .removeEmployee1
                : undefined
            }
            loadServiceEndpoint={
              activityPermitAPI(undefined, getBaseUrl(), API)
                .getApplicationService
            }
            refresh={loadActivityLicense}
            isApplicationView
          />
        )}
        <FilesRow
          isOpen={isFilesRowOpen}
          handleClick={() => setIsFilesRowOpen(!isFilesRowOpen)}
          documents={activityLicenseApplication?.fileReferences || []}
        />
        <DecisionsRow
          isOpen={isDecisionsRowOpen}
          handleClick={() => setIsDecisionsRowOpen(!isDecisionsRowOpen)}
          decisions={activityLicenseApplication?.decisions}
        />
      </td>
    </tr>
  );
};
