import React, { useCallback, useEffect, useState } from "react";
import { Button, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getBaseUrl, API } from "../../../../api";
import { Loader } from "../../../../Component/Loader/Loader";
import { CompanyInfoRow } from "../Applications/CompanyInfoRow";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import {
  formatDate,
  renderOverViewRow
} from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { formatProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import { FormattedButton } from "../../../../Component/Button/FormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { ApplicationDeleteConfirmationModal } from "../../Application/Table/ApplicationDeleteConfirmationModal";
import { StateFeeFM } from "../../../../Messages/StateFeeFM";
import { StateFeeInfoModal } from "../../../Shared/StateFee/StateFeeInfoModal";
import { licenseAcquisitionActions } from "../../../../ActivityLicense/licenseAcquisitionActions";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { FilesRow } from "../Applications/FilesRow";
import { ActivityLocationsRow } from "../../../Shared/ActivityLicense/Locations/ActivityLocationsRow";
import { DecisionsRow } from "../Applications/DecisionsRow";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { RenewalModal } from "../Renewal/RenewalModal";
import { LicenseAcquisitionRequiredFiles } from "../../../Shared/ActivityLicense/LicenseAcquisition/LicenseAcquisitionDocumentsContainer";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  LicenseAcquisition,
  ActivityLicenseApplicationStatus,
  FileReference,
  TegevuslubadeLevtmisteTeenusApiFactory as activityPermitIssuanceAPI,
  TegevuslubadeTeenusApiFactory as activityPermitAPI, FileObjectType, FileDeleteType
} from "../../../../../api_client/medre_api";
import { displayAlert } from "../../../../Util/AlertUtil";

interface Props {
  isOpen?: boolean;
  id?: string;
  handleDelete: () => void;
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum;
  reloadApplications: () => void;
}

export const LicenseAcquisitionAccordion = ({
  isOpen,
  id,
  proceedingType,
  handleDelete,
  reloadApplications
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [licenseAcquisition, setLicenseAcquisition] =
    useState<LicenseAcquisition>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [stateFeeModalIsOpen, setStateFeeModalIsOpen] = useState(false);
  const [isApplicantInfoRowOpen, setIsApplicantInfoRowOpen] = useState(false);
  const [isTransferorInfoRowOpen, setIsTransferorInfoRowOpen] = useState(false);
  const [isFilesRowOpen, setIsFilesRowOpen] = useState(false);
  const [isActivityLocationsRowOpen, setIsActivityLocationsRowOpen] =
    useState(false);
  const [isDecisionsRowOpen, setIsDecisionsRowOpen] = useState(false);
  const [renewalModalIsOpen, setRenewalModalIsOpen] = useState(false);

  const stateFee = licenseAcquisition?.stateFee;
  const currentStatus = licenseAcquisition?.status;
  const notSubmitted = currentStatus === ActivityLicenseApplicationStatus.Saved;
  const activityLicenseLocations = licenseAcquisition?.license?.locations;
  const businessArea = licenseAcquisition?.license?.businessArea;

  const loadLicenseAcquisition = useCallback(() => {
    setIsLoading(true);
    activityPermitIssuanceAPI(undefined, getBaseUrl(), API)
      .getPortalActivityLicenseApplication(id as string, {
        withCredentials: true
      })
      .then((res) => setLicenseAcquisition(res.data))
      .finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      loadLicenseAcquisition();
    }
  }, [isOpen, loadLicenseAcquisition]);

  if (isLoading) {
    return <Loader backdrop={true} />;
  }

  const proceedWithDraft = () => {
    dispatch(
      licenseAcquisitionActions.setLicenseAcquisitionApplication(
        licenseAcquisition!
      )
    );
    history.push("/new-license-acquisition");
  };

  const handleUploadSuccess = (files: FileReference[]) => {
    setLicenseAcquisition({
      ...(licenseAcquisition as LicenseAcquisition),
      fileReferences: [...(licenseAcquisition?.fileReferences || []), ...files]
    });
  };

  const handleDeleteSuccess = (fileName: string) => {
    setLicenseAcquisition((prevState) => {
      const files = prevState?.fileReferences?.filter(
        (f) => f.minioFileName !== fileName
      );
      return {
        ...prevState,
        fileReferences: files
      } as LicenseAcquisition;
    });
  };

  const handleApplicationDelete = () => {
    activityPermitIssuanceAPI(undefined, getBaseUrl(), API)
      .deleteActivityLicenseApplication(id as string, {
        withCredentials: true
      })
      .then(() => {
        handleDelete();
        displayAlert(
          "applicationDraftDeleteSuccess",
          AlertType.Success,
          dispatch
        );
      })
      .catch(() => {
        displayAlert(
          "applicationDraftDeleteFailure",
          AlertType.Danger,
          dispatch
        );
      })
      .finally(() => setDeleteModalIsOpen(false));
  };

  const handleContinueProceeding = () => {
    activityPermitIssuanceAPI(undefined, getBaseUrl(), API)
      .continueApplicationProceeding2(id as string, {
        withCredentials: true
      })
      .then(() => {
        displayAlert("renewApplicationSuccess", AlertType.Success, dispatch);
        reloadApplications();
      })
      .catch(() =>
        displayAlert("renewApplicationFailure", AlertType.Danger, dispatch)
      );
  };

  const renderButtonRow = () => {
    if (currentStatus === ActivityLicenseApplicationStatus.Saved) {
      return (
        <>
          <Row className="justify-content-md-end justify-content-center application-header-row mb-3 mt-3">
            <FormattedButton
              id="deleteApplication"
              onClick={() => setDeleteModalIsOpen(true)}
            />
            <PrimaryFormattedButton
              id="continueWithSavedDraft"
              onClick={proceedWithDraft}
            />
          </Row>
          <ApplicationDeleteConfirmationModal
            applicationNumber={licenseAcquisition?.applicationNumber!}
            isOpen={deleteModalIsOpen}
            onClose={() => setDeleteModalIsOpen(false)}
            onDelete={handleApplicationDelete}
          />
        </>
      );
    }
    if (!notSubmitted && !stateFee?.paid) {
      return (
        <>
          <Row className="justify-content-md-end justify-content-center application-header-row mb-3 mt-3">
            <Button
              className="ml-1"
              onClick={() => setStateFeeModalIsOpen(true)}
            >
              <StateFeeFM id="stateFeeInstructionHeader" />
            </Button>
          </Row>
          <StateFeeInfoModal
            isOpen={stateFeeModalIsOpen}
            onClose={() => setStateFeeModalIsOpen(false)}
            deadline={stateFee?.stateFeeDeadline}
            referenceNumber={stateFee?.referenceNumber}
            stateFeeValue={stateFee?.stateFeeValue}
          />
        </>
      );
    }
    if (currentStatus === ActivityLicenseApplicationStatus.Paused) {
      return (
        <>
          <Row className="justify-content-md-end justify-content-center application-header-row mb-3 mt-3">
            <PrimaryFormattedButton
              id="renewApplication"
              className="ml-1"
              onClick={() => setRenewalModalIsOpen(true)}
            />
          </Row>
          <RenewalModal
            hasOther={false}
            isOpen={renewalModalIsOpen}
            onClose={() => setRenewalModalIsOpen(false)}
            applicationId={id || ""}
            notes={licenseAcquisition?.renewalNotes || ""}
            requiredFiles={LicenseAcquisitionRequiredFiles || []}
            optionalFile={FileObjectType.LicenseAcquisitionPersonnelList}
            fileReferences={licenseAcquisition?.fileReferences || []}
            onUploadSuccess={handleUploadSuccess}
            onDeleteSuccess={handleDeleteSuccess}
            renewApplication={handleContinueProceeding}
            deleteType={FileDeleteType.LicenseAcquisition}
          />
        </>
      );
    }
    return null;
  };

  const getStateFeeText = () => {
    if (stateFee?.paid) {
      return <StateFeeFM id="paid" />;
    } else {
      return (
        <StateFeeFM
          id="notPaidWithButton"
          values={{
            button: (msg: string) => (
              <Button
                className="p-0 link-button"
                color="link"
                onClick={() => setStateFeeModalIsOpen(true)}
              >
                {msg}
              </Button>
            )
          }}
        />
      );
    }
  };

  const state = (
    <ActivityLicenseApplicationState
      currentStatus={licenseAcquisition?.status}
      isDetailed={false}
    />
  );
  return (
    <tr className="detail-info">
      <td colSpan={7} className="pt-0 pb-0">
        {!isLoading && renderButtonRow()}
        <div className="detail-col pl-0 pr-0">
          <Row className="col-header">
            <GeneralFM id="applicationData" />
          </Row>
          {renderOverViewRow(
            "activityLicense",
            "proceedingType",
            formatProceedingType(proceedingType!)
          )}
          {renderOverViewRow(
            "activityLicense",
            "applicationNumber",
            licenseAcquisition?.applicationNumber?.toString()
          )}
          {renderOverViewRow(
            "activityLicense",
            "licenseNumber",
            licenseAcquisition?.license?.number
          )}
          {renderOverViewRow(
            "activityLicense",
            notSubmitted ? "savedDate" : "submissionDate",
            formatDate(
              notSubmitted
                ? licenseAcquisition?.modifiedAt
                : licenseAcquisition?.submissionTime
            )
          )}
          {!notSubmitted &&
            !stateFee?.paid &&
            renderOverViewRow(
              "activityLicense",
              "stateFeeDeadline",
              formatDate(stateFee?.stateFeeDeadline)
            )}
          {renderOverViewRow("application", "state", state)}
          {!notSubmitted && (
            <>
              {renderOverViewRow("stateFee", "stateFee", getStateFeeText())}
              {renderOverViewRow(
                "stateFee",
                "stateFeeSum",
                `${stateFee?.stateFeeValue?.toString()} €`
              )}
            </>
          )}
          {renderOverViewRow(
            "activityLicense",
            "tisIntegration",
            <ActivityLicenseFM id={"tisIntegrationConfirmation"} />
          )}
        </div>
        <CompanyInfoRow
          isOpen={isApplicantInfoRowOpen}
          handleClick={() => setIsApplicantInfoRowOpen(!isApplicantInfoRowOpen)}
          company={licenseAcquisition?.company}
          submittingPerson={
            licenseAcquisition?.submittingPersonFirstName +
            " " +
            licenseAcquisition?.submittingPersonLastName
          }
          submittingPersonIdCode={licenseAcquisition?.submittingPersonIdCode}
        />
        <CompanyInfoRow
          titleId={"transferorInfo"}
          submittable={false}
          isOpen={isTransferorInfoRowOpen}
          handleClick={() =>
            setIsTransferorInfoRowOpen(!isTransferorInfoRowOpen)
          }
          company={licenseAcquisition?.transferor}
        />
        {licenseAcquisition?.license?.locations && (
          <ActivityLocationsRow
            isOpen={isActivityLocationsRowOpen}
            handleClick={() =>
              setIsActivityLocationsRowOpen(!isActivityLocationsRowOpen)
            }
            activityLocations={activityLicenseLocations}
            loadServiceEndpoint={
              activityPermitAPI(undefined, getBaseUrl(), API).getService1
            }
            isHospital={businessArea?.isHospital!}
          />
        )}
        <FilesRow
          isOpen={isFilesRowOpen}
          handleClick={() => setIsFilesRowOpen(!isFilesRowOpen)}
          documents={licenseAcquisition?.fileReferences || []}
        />
        <DecisionsRow
          isOpen={isDecisionsRowOpen}
          handleClick={() => setIsDecisionsRowOpen(!isDecisionsRowOpen)}
          decisions={licenseAcquisition?.decisions}
        />
      </td>
    </tr>
  );
};
