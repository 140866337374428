import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { AttachmentContainer } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import { saveFilesWithAlerts } from "../../../../Component/FileDropzone/UploadFile";
import {
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityAPI,
  DetailedActivityLicense,
  FileObjectType,
  FileReference,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";
import "./ExternalFiles.scss";
import { API, getBaseUrl } from "../../../../api";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AlertType } from "../../../../Dto/Alert/AlertItem";

interface Props {
  licenseId: string;
  setLicense: (
    license: (
      prevState?: DetailedActivityLicense
    ) => DetailedActivityLicense | undefined
  ) => void;
  files: FileReference[];
}

export const ExternalFiles = ({ licenseId, setLicense, files }: Props) => {
  const dispatch = useDispatch();
  const [activeAttachmentType, setActiveAttachmentType] =
    useState<FileObjectType | null>(null);

  const handleSaveFiles = (addedFiles: File[]) => {
    saveFilesWithAlerts(
      addedFiles,
      FileObjectType.LicenseExternalFile,
      licenseId,
      dispatch,
      onUploadSuccess
    );
  };

  const onUploadSuccess = (addedFiles: FileReference[]) => {
    setLicense((prevState) => {
      if (!prevState) {
        return undefined;
      }
      if (prevState.externalFiles) {
        return {
          ...prevState,
          externalFiles: [...prevState.externalFiles, ...addedFiles]
        };
      }
      return { ...prevState, externalFiles: addedFiles };
    });
  };

  const handleFileDelete = (fileName: string) => {
    const toDelete = files.find((f) => f.fileName === fileName);
    if (toDelete && toDelete.minioFileName) {
      officialActivityAPI(undefined, getBaseUrl(), API)
        .deleteActivityLicenseDocument(licenseId, toDelete.fileObjectType!, toDelete.minioFileName, {
          withCredentials: true
        })
        .then(() => {
          removeFileReference(toDelete.minioFileName!)
          displayAlert("fileDeleteSuccessful", AlertType.Info, dispatch);
        })
        .catch(() => displayAlert("fileDeleteFailure", AlertType.Danger, dispatch));
    }
  };

  const removeFileReference = (minioFileName: string) => {
    const filteredFiles = files.filter(
      (f) => f.minioFileName !== minioFileName
    );
    setLicense((prevState) => {
      if (!prevState) {
        return undefined;
      }
      return { ...prevState, externalFiles: filteredFiles };
    });
  };

  return (
    <div className="external-files-container">
      <h2 className="mb-4">Dokumendid</h2>
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.LicenseExternalFile)
        }
        isActive={activeAttachmentType === FileObjectType.LicenseExternalFile}
        type={FileObjectType.LicenseExternalFile}
        fileReferences={files}
        maxFilesCount={50}
        saveFiles={handleSaveFiles}
        handleFileDelete={handleFileDelete}
        allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede
        ]}
      />
    </div>
  );
};
