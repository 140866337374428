import React from "react";
import { ActivityLicenseDocumentsContainer } from "../../../Shared/ActivityLicense/Documents/ActivityLicenseDocumentsContainer";
import { ActivityLicenseOverview } from "../../../Shared/ActivityLicense/Overview/ActivityLicenseOverview";
import { useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { FormattedMessage } from "react-intl";
import { Stepper } from "../../../../Component/ProgressBar/Stepper";
import { Card } from "reactstrap";
import { WizardProgressBar } from "../../../../Component/ProgressBar/WizardProgressBar";
import {
  ActivityLicenseServiceStep,
  activityLicenseServiceSteps
} from "../../../Shared/ActivityLicense/Services/ActivityLicenseServiceStep";
import { NewActivityLicenseServices } from "../../../Shared/ActivityLicense/NewActivityLicenseServices";
import { ActivityLicenseFooter } from "../../../Shared/ActivityLicense/ActivityLicenseFooter";

export const PortalActivityLicenseServiceContainer = () => {
  const currentStep = useSelector(
    (state: RootState) => state.activityLicenseApplication.currentStep || 0
  );
  const steps = activityLicenseServiceSteps;

  const urls = {
    routes: {
      portal: "/activity-license-service-application",
      official: "/activity-license-service-applications"
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case ActivityLicenseServiceStep.SERVICES:
      default:
        return <NewActivityLicenseServices />;
      case ActivityLicenseServiceStep.DOCUMENTS:
        return <ActivityLicenseDocumentsContainer />;
      case ActivityLicenseServiceStep.OVERVIEW:
        return <ActivityLicenseOverview />;
    }
  };

  return (
    <div className="activity-license-container">
      <h1 className="ml-3">
        <FormattedMessage
          id="newActivityLicenseService.title"
          defaultMessage="Teenuse lisamine"
        />
      </h1>

      <Stepper className={"m-3"} currentStep={currentStep || 0} steps={steps} />
      <Card className="ml-3 mt-4 mb-5 mr-3">
        <div className="m-4">
          <WizardProgressBar highestStep={currentStep} steps={steps} />
          {renderContent()}
          <hr className="mt-5 application-footer-hr" />
          <ActivityLicenseFooter
            currentStep={currentStep || 0}
            steps={steps}
            urls={urls}
            isService={true}
          />
        </div>
      </Card>
    </div>
  );
};
