import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import "./RenewalModal.scss";
import { EducationEditFields } from "../../../Shared/Application/Education/Manual/EducationEditFields";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { AttachmentFields } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentFields";
import { API, getBaseUrl } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import {
  EducationInfo,
  FileObjectType,
  FileReference,
  TaotluseAndmeteTeenusApiFactory as applicationDataAPI
} from "../../../../../api_client/medre_api";
import { saveFilesWithAlerts } from "../../../../Component/FileDropzone/UploadFile";
import { AutomaticEducationView } from "../../../Shared/Application/Education/Automatic/AutomaticEducationView";
import { displayAlert } from "../../../../Util/AlertUtil";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notes: string;
  applicationId: string;
  educationInfo: EducationInfo;
  fileReferences: FileReference[];
  occupationCode?: string;
  onUploadSuccess: (files: FileReference[]) => void;
  onDeleteSuccess: (minioFileName: string) => void;
  onDeleteMultipleSuccess: (minioFileNames: string[]) => void;
  reloadApplications: () => void;
  personId: string;
}

export const RenewalModal = ({
  isOpen,
  onClose,
  notes,
  applicationId,
  educationInfo,
  fileReferences,
  occupationCode,
  onUploadSuccess,
  onDeleteSuccess,
  onDeleteMultipleSuccess,
  reloadApplications,
  personId
}: Props) => {
  const dispatch = useDispatch();
  const [educationInfoForm, setEducationInfoForm] = useState(educationInfo);
  const [isFormValid, setIsFormValid] = useState(true);
  const [areFilesPresent, setAreFilesPresent] = useState(true);
  const [newFileReferences, setNewFileReferences] = useState<FileReference[]>(
    []
  );
  const [isEhisViewActive, setIsEhisViewActive] = useState(educationInfo ? educationInfo.fromEHIS : false);
  const [educationFoundFromEHIS, setEducationFoundFromEHIS] = useState<boolean>(false);

  const renewApplication = () => {
    if (isFormValid && areFilesPresent) {
      applicationDataAPI(undefined, getBaseUrl(), API)
        .updateEducationInfo1(applicationId, educationInfoForm, {
          withCredentials: true
        })
        .then(() => {
          const alertMessage = <AlertMessage id="renewApplicationSuccess" />;
          const alert = {
            id: 0,
            type: AlertType.Success,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
          reloadApplications();
        })
        .catch(() => {
          const alertMessage = <AlertMessage id="renewApplicationFailure" />;
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        });
    }
  };

  const handleFileUploadSuccess = (files: FileReference[]) => {
    setNewFileReferences([...newFileReferences, ...files]);
    onUploadSuccess(files);
  };

  const handleSaveFiles = (files: File[], fileType: FileObjectType) => {
    saveFilesWithAlerts(
      files,
      fileType,
      applicationId,
      dispatch,
      handleFileUploadSuccess
    );
  };

  const handleFileDelete = (fileName: string, fileType: FileObjectType) => {
    let toDelete = fileReferences.filter(
      (file) => file.fileName === fileName && file.fileObjectType === fileType
    )[0];
    if (toDelete && toDelete.minioFileName) {
      applicationDataAPI(undefined, getBaseUrl(), API)
        .deleteApplicationFile(applicationId, toDelete.fileObjectType!, toDelete.minioFileName, {
          withCredentials: true
        })
        .then(() => {
          handleFileDeleteSuccess(toDelete.minioFileName!);
          displayAlert("fileDeleteSuccessful", AlertType.Info, dispatch);
        })
        .catch(() => displayAlert("fileDeleteFailure", AlertType.Danger, dispatch));
    }
  };

  const handleFileDeleteSuccess = (minioFileName: string) => {
    setNewFileReferences(
      newFileReferences.filter((ref) => ref.minioFileName !== minioFileName)
    );
    onDeleteSuccess(minioFileName);
  };

  const deleteNewFilesAndClose = () => {
    newFileReferences.forEach((reference) =>
      applicationDataAPI(undefined, getBaseUrl(), API)
        .deleteApplicationFile(applicationId, reference.fileObjectType!, reference.minioFileName!)
        .then(() => {})
        .catch(() => displayAlert("fileDeleteFailure", AlertType.Danger, dispatch))
    );
    onDeleteMultipleSuccess(
      newFileReferences.map((reference) => reference.minioFileName || "")
    );
    setNewFileReferences([]);
    onClose();
  };

  const renderManualEducationFields = () => {
    return (
      <>
        <div className="education-fields">
          <EducationEditFields
            occupationCode={occupationCode}
            educationInfo={educationInfoForm}
            updateEducationInfo={setEducationInfoForm}
            updateIsFormValid={setIsFormValid}
            isCountrySelectDisabled
            isTHTDetailsPage={false}
          />
        </div>
        <AttachmentFields
          updateAreFilesValid={setAreFilesPresent}
          fileReferences={fileReferences}
          objectId={applicationId}
          saveFiles={handleSaveFiles}
          handleFileDelete={handleFileDelete}
        />
      </>
    );
  };

  const handleEhisEducationState = (state: boolean): void => {
    setEducationFoundFromEHIS(state);
  };

  const renderEhisEducationView = () => {
    return (
      <>
        <AutomaticEducationView
          toggleAutomaticEducationLoad={(): void => setIsEhisViewActive(false)}
          occupationCode={occupationCode}
          setIsSaveAllowed={setIsFormValid}
          educationInfo={educationInfoForm}
          updateEducationInfo={setEducationInfoForm}
          isManualViewButtonHidden={educationInfo.fromEHIS}
          personId={personId}
          isRenewalModalView
          fileReferences={fileReferences}
          applicationId={applicationId}
          saveFiles={handleSaveFiles}
          deleteFile={handleFileDelete}
          onEhisEducationStateChange={handleEhisEducationState}
        />
      </>
    );
  };

  return (
    <Modal className="renewal-modal" isOpen={isOpen}>
      <ModalHeader tag="h4">
        <FormattedMessage
          id="RenewalModal.header"
          defaultMessage="Taotluse täiendamine"
        />
      </ModalHeader>
      <ModalBody>
        <h6>
          <FormattedMessage
            id="RenewalModal.renewalHeader"
            defaultMessage="Ametniku poolt lisatud puuduliku taotluse põhjus"
          />
        </h6>
        { notes }
        { isEhisViewActive ? renderEhisEducationView() : renderManualEducationFields()}
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="backToApplication"
          onClick={(): void => {
            deleteNewFilesAndClose();
            setEducationInfoForm(educationInfo);
          }}
        />
        <PrimaryFormattedButton
          id="renewApplication"
          disabled={educationFoundFromEHIS}
          onClick={renewApplication}
        />
      </ModalFooter>
    </Modal>
  );
};
