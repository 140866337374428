import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import "./RenewalModal.scss";
import { EducationEditFields } from "../../../Shared/Application/Education/Manual/EducationEditFields";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { AttachmentFields } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentFields";
import { FileReferenceDto, fileToRef } from "../../../../Dto/File/FileReference";
import { API, getBaseUrl } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { AutomaticEducationView } from "../../../Shared/Application/Education/Automatic/AutomaticEducationView";
import { saveFiles } from "../../../../Component/FileDropzone/UploadFile";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import {
  SwitchToAutomaticEducationViewModal
} from "../../../Shared/Application/Education/SwitchToAutomaticEducationViewModal";
import {
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI,
  DetailedApplication,
  EducationInfo,
  FileObjectType,
  FileReference
} from "../../../../../api_client/medre_api";
import { Loader } from "../../../../Component/Loader/Loader";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  personId: string;
  applicationId: string;
  educationInfo: EducationInfo;
  fileReferences: FileReference[];
  occupationCode?: string;
  onRenewalSuccess: (application: DetailedApplication) => void;
}

export const RenewalModal = ({
  isOpen,
  onClose,
  personId,
  applicationId,
  educationInfo,
  occupationCode,
  onRenewalSuccess,
  fileReferences
}: Props) => {
  const dispatch = useDispatch();
  const [educationInfoForm, setEducationInfoForm] = useState(educationInfo);
  const [isFormValid, setIsFormValid] = useState(true);
  const [fileRefs, setFileRefs] = useState<FileReferenceDto[]>(fileReferences);
  const [filesToDelete, setFilesToDelete] = useState<FileReference[]>([]);
  const [areFilesPresent, setAreFilesPresent] = useState(true);
  const isEhisViewPossible = educationInfo.countryCode === "EST";
  const [isEhisViewActive, setIsEhisViewActive] = useState(educationInfo ? educationInfo.fromEHIS : false);
  const [isSwitchConfirmationModalOpen, setIsSwitchConfirmationModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFileReferencesUpdated, setIsFileReferencesUpdated] = useState(false);
  const [educationFoundFromEHIS, setEducationFoundFromEHIS] = useState<boolean>(false);
  const [isEducationChanged, setIsEducationChanged] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setFileRefs(fileReferences);
      setFilesToDelete([]);
      setIsEhisViewActive(educationInfo ? educationInfo.fromEHIS : false);
    }
  }, [isOpen, fileReferences, educationInfo.fromEHIS]);

  useEffect(() => {
    if (!isEhisViewActive) {
      setFileRefs(fileReferences);
      setFilesToDelete([]);
    }
  }, [isEhisViewActive, fileReferences]);

  useEffect(() => {
    if (isOpen) {
      const propertiesToCheck = [
        "fromEHIS",
        "specialization",
        "countryCode",
        "diplomaDate",
        "diplomaNumber",
        "curriculumCode",
        "curriculumName",
        "schoolName"
      ];

      const allPropertiesMatch = propertiesToCheck.every(property =>
        educationInfo[property] === educationInfoForm[property]
      );

      allPropertiesMatch ? setIsEducationChanged(false) : setIsEducationChanged(true);
    }
  }, [educationInfo, educationInfoForm]);

  const renewApplication = (): void => {
    if (isFormValid && areFilesPresent) {
      setIsSaving(true);
      Promise.all([deleteFiles(), addFilesToMinio()])
        .then(() => {
          officialAppDataAPI(undefined, getBaseUrl(), API)
            .updateEducationInfo(applicationId, educationInfoForm, {
              withCredentials: true
            })
            .then((response) => {
              const alertMessage = (
                <AlertMessage id="renewApplicationSuccess" />
              );
              const alert = {
                id: 0,
                type: AlertType.Success,
                message: alertMessage
              };
              setIsSaving(false);
              dispatch(alertActions.addAlert(alert));
              onRenewalSuccess(response.data);
              setIsFileReferencesUpdated(false);
              setIsEducationChanged(false);
            })
            .catch(() => {
              const alertMessage = (
                <AlertMessage id="renewApplicationFailure" />
              );
              const alert = {
                id: 0,
                type: AlertType.Danger,
                message: alertMessage
              };
              dispatch(alertActions.addAlert(alert));
              setIsSaving(false);
            });
        })
        .catch(() => {
          const alertMessage = <AlertMessage id="renewApplicationFailure" />;
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
          setIsSaving(false);
        });
    }
  };

  const addFiles = (files: File[], fileType: FileObjectType): void => {
    setFileRefs((prevState) => {
      return [...prevState, ...files.map((f) => fileToRef(f, fileType))];
    });
    setIsFileReferencesUpdated(true);
  };

  const handleFileDelete = (fileName: string, fileType: FileObjectType): void => {
    let toDelete = fileRefs.filter(
      (file) => file.fileName === fileName && file.fileObjectType === fileType
    )[0];
    setFilesToDelete((prevFiles: FileReference[]) => {
      return [...prevFiles, toDelete];
    });
    setFileRefs((prevState: FileReference[]) =>
      prevState.filter(
        (f) => f.fileObjectType !== fileType || f.fileName !== fileName
      )
    );
    setIsFileReferencesUpdated(true);
  };

  const addFilesToMinio = async (): Promise<void> => {
    for (let file of fileRefs) {
      if (!file.minioFileName) {
        await saveFiles([file.file!], file.fileObjectType!, applicationId);
      }
    }
  };

  const deleteFiles = async (): Promise<void> => {
    for (let file of filesToDelete) {
      if (file.minioFileName) {
        officialAppDataAPI(undefined, getBaseUrl(), API)
          .deleteOfficialApplicationFile(applicationId, file.fileObjectType!, file.minioFileName, {
            withCredentials: true
          })
      }
    }
  };

  const renderManualFields = () => {
    return (
      <>
        <div className="education-fields">
          <EducationEditFields
            occupationCode={occupationCode}
            educationInfo={educationInfoForm}
            updateEducationInfo={setEducationInfoForm}
            updateIsFormValid={setIsFormValid}
            isCountrySelectDisabled
            isTHTDetailsPage={false}
          />
        </div>
        <AttachmentFields
          updateAreFilesValid={setAreFilesPresent}
          fileReferences={fileRefs}
          objectId={applicationId}
          saveFiles={addFiles}
          handleFileDelete={handleFileDelete}
        />
        { isEhisViewPossible && (
          <div className="switch-to-automatic-text-wrapper">
            Eestis omandatud hariduse info saate
            <LinkButton
              id="setAutomatically"
              className="p-0"
              onClick={() => setIsSwitchConfirmationModalOpen(true)}
            />
          </div>
        ) }
      </>
    );
  };

  const handleEhisEducationState = (state: boolean): void => {
    setEducationFoundFromEHIS(state);
  };

  const renderEhisFields = () => (
    <>
      <AutomaticEducationView
        toggleAutomaticEducationLoad={(): void => setIsEhisViewActive(false)}
        occupationCode={occupationCode}
        setIsSaveAllowed={setIsFormValid}
        educationInfo={educationInfoForm}
        updateEducationInfo={setEducationInfoForm}
        isManualViewButtonHidden={educationInfo.fromEHIS}
        personId={personId}
        isRenewalModalView
        onEhisEducationStateChange={handleEhisEducationState}
        fileReferences={fileRefs}
        applicationId={applicationId}
        saveFiles={addFiles}
        deleteFile={handleFileDelete}
      />
    </>
  );

  const handleSwitch = (): void => {
    setIsSwitchConfirmationModalOpen(false);
    setIsEhisViewActive(true);
    setIsFormValid(false);
    setFilesToDelete((prevFiles: FileReference[]) => {
      return [...prevFiles, ...fileRefs.filter(f =>
        f.fileObjectType === FileObjectType.ProceedingDiploma ||
        f.fileObjectType === FileObjectType.ProceedingAcademicReport)];
    });
  };

  const isRenewalButtonDisabled = (): boolean => {
    if (isEhisViewActive) {
      if (educationFoundFromEHIS) {
        return true;
      }
      return !(isFileReferencesUpdated || isEducationChanged);
    } else {
      return !isFormValid || !areFilesPresent;
    }
  };

  return (
    <>
      { isSaving && (
        <Loader absolute wrapperClassName="renewal-modal-loader-class" />
      ) }
      <Modal className="renewal-modal" isOpen={isOpen}>
        <ModalHeader tag="h4">
          <FormattedMessage
            id="RenewalModal.header"
            defaultMessage="Taotluse täiendamine"
          />
        </ModalHeader>
        <ModalBody>
          { isEhisViewPossible && isEhisViewActive
            ? renderEhisFields()
            : renderManualFields() }
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="backToApplication"
            onClick={(): void => {
              onClose();
              setIsFileReferencesUpdated(false);
              setIsEducationChanged(false);
              setEducationInfoForm(educationInfo);
            }}
          />
          <PrimaryFormattedButton
            id="renewApplication"
            disabled={isRenewalButtonDisabled()}
            onClick={renewApplication}
          />
        </ModalFooter>
      </Modal>
      <SwitchToAutomaticEducationViewModal
        isOpen={isSwitchConfirmationModalOpen}
        onClose={(): void => setIsSwitchConfirmationModalOpen(false)}
        onSave={(): void => handleSwitch()}
      />
    </>
  );
};
