import React, { useState } from "react";

import { ProtectedFieldWrapper } from "../../../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { AddProtocolModal } from "./AddProtocolModal";
import {
  FileDeleteType,
  FileObjectType,
  FileReference, OfficialUser,
  OfficialUserPrivilegesEnum
} from "../../../../../../../api_client/medre_api";
import { ActionButtonClasses } from "../../../../../../Component/Button/Button";
import { ProtectedComponent } from "../../../../../../Security/ProtectedComponent";
import { DangerFormattedButton } from "../../../../../../Component/Button/DangerFormattedButton";
import {
  ApplicationDeleteConfirmationModal
} from "../../../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { OfficialForwardingModal } from "../Proceeding/OfficialForwardingModal";

interface Props {
  addProtocol: (protocolNumber: string) => Promise<void>;
  applicationNumber?: number;
  deleteApplication?: (applicationId: string) => void;
  fileObjectType: FileObjectType;
  fileReferences: FileReference[];
  handleForwardToOfficial: (official: OfficialUser) => void;
  id: string | undefined;
  refresh: () => void;
  deleteType: FileDeleteType;
}

export const ProtocolInPreparationActions = ({
  addProtocol,
  applicationNumber,
  deleteApplication,
  fileObjectType,
  fileReferences,
  handleForwardToOfficial,
  id,
  refresh,
  deleteType
}: Props) => {
  const [isAddProtocolModalOpen, setIsAddProtocolModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOfficialForwardingModalOpen, setIsOfficialForwardingModalOpen] =
    useState(false);

  const getFilesOfType = (objectType: FileObjectType) =>
    fileReferences?.filter((file) => file.fileObjectType === objectType) || [];

  const handleAppDelete = () => {
    deleteApplication!(id!);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        <PrimaryFormattedButton
          id="addProtocol"
          className={ActionButtonClasses}
          onClick={() => setIsAddProtocolModalOpen(true)}
        />
        <AddProtocolModal
          isOpen={isAddProtocolModalOpen}
          onClose={() => setIsAddProtocolModalOpen(false)}
          onSubmit={addProtocol}
          applicationId={id}
          fileReferences={getFilesOfType(fileObjectType)}
          type={fileObjectType}
          refresh={refresh}
          deleteType={deleteType}
        />
      </ProtectedFieldWrapper>
      {deleteApplication && id && (
        <ProtectedComponent allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede,
        ]}>
          <DangerFormattedButton
            id="deleteApplication"
            className={ActionButtonClasses}
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </ProtectedComponent>
      )}
      <ProtectedComponent
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}>
        <SecondaryFormattedButton
          id={"forwardToOfficial"}
          className={ActionButtonClasses}
          onClick={() => setIsOfficialForwardingModalOpen(true)}
        />
      </ProtectedComponent>
      <ApplicationDeleteConfirmationModal
        applicationNumber={applicationNumber || 0}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleAppDelete}
      />
      <OfficialForwardingModal
        isOpen={isOfficialForwardingModalOpen}
        onClose={() => setIsOfficialForwardingModalOpen(false)}
        forwardToOfficial={(official) => {
          handleForwardToOfficial(official);
          setIsOfficialForwardingModalOpen(false);
        }}
      />
    </>
  );
};
