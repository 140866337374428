import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { AttachmentContainer } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import { saveFilesWithAlerts } from "../../../../Component/FileDropzone/UploadFile";
import { ApplicantChanges } from "../../../../Dto/Applicant/ApplicantChanges";
import {
  FailideLeslaadimiseTeenusApiFactory as fileUploadAPI,
  FileObjectType,
  FileReference,
  OfficialUserPrivilegesEnum,
  Person
} from "../../../../../api_client/medre_api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { API, getBaseUrl } from "../../../../api";
import { displayAlert } from "../../../../Util/AlertUtil";

interface Props {
  person: Person;
  applicantChanges: ApplicantChanges;
  setApplicantChanges: (
    applicantChanges: (prevState: ApplicantChanges) => ApplicantChanges
  ) => void;
}

export const ExternalDecisionUpload = ({
  applicantChanges,
  setApplicantChanges,
  person
}: Props) => {
  const dispatch = useDispatch();
  const externalDecision = applicantChanges.externalDecision;
  const [activeAttachmentType, setActiveAttachmentType] =
    useState<FileObjectType | null>(null);

  const handleSaveFiles = (files: File[]) => {
    const fileNames = person.externalDecisions?.map((decision) =>
      decision.fileReference?.fileName);
    if (files.some((file) => fileNames?.includes(file.name))) {
      const alertMessage = <AlertMessage id="fileNameNotUnique" />;
      const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      return dispatch(alertActions.addAlert(alert));
    }

    saveFilesWithAlerts(
      files,
      FileObjectType.PersonExternalDecisionDocument,
      person.id!,
      dispatch,
      onUploadSuccess
    );
  };

  const onUploadSuccess = (files: FileReference[]) => {
    setApplicantChanges((prevState: ApplicantChanges) => {
      return { ...prevState, externalDecision: { fileReference: files[0] } };
    });
  };

  const handleFileDelete = (fileName: string) => {
    const toDelete = externalDecision?.fileReference;
    if (toDelete && toDelete.minioFileName) {
      fileUploadAPI(undefined, getBaseUrl(), API)
        .deleteFile(toDelete.fileObjectType!, toDelete.minioFileName, { withCredentials: true })
        .then(() => {
          removeFileReference(toDelete.minioFileName!);
          displayAlert("fileDeleteSuccessful", AlertType.Info, dispatch);
        })
        .catch(() => displayAlert("fileDeleteFailure", AlertType.Danger, dispatch));
    }
  };

  const removeFileReference = (minioFileName: string) => {
    setApplicantChanges((prevState: ApplicantChanges) => {
      return { ...prevState, externalDecision: undefined };
    });
  };

  const fileReferenceArray = externalDecision?.fileReference
    ? [externalDecision.fileReference]
    : [];

  return (
    <div className="applicant-changes-form external-decisions-upload">
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.PersonExternalDecisionDocument)
        }
        isActive={
          activeAttachmentType === FileObjectType.PersonExternalDecisionDocument
        }
        type={FileObjectType.PersonExternalDecisionDocument}
        fileReferences={fileReferenceArray}
        maxFilesCount={1}
        saveFiles={(files) => handleSaveFiles(files)}
        handleFileDelete={(fileName) => handleFileDelete(fileName)}
        allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede
        ]}
      />
    </div>
  );
};
