import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { LinkButton } from "../../../../../Component/Button/LinkButton";
import { EducationEditFields } from "./EducationEditFields";
import { SwitchToAutomaticEducationViewModal } from "../SwitchToAutomaticEducationViewModal";
import { RootState } from "../../../../../rootReducer";
import { applicationDraftActions } from "../../../../../Application/applicationDraftActions";
import { AttachmentFields } from "./Attachment/AttachmentFields";
import { getBaseUrl, API } from "../../../../../api";
import { isSwitchToAutomaticEducationPossible } from "../AutomaticEducationPossible";
import {
  FileObjectType,
  EducationInfo,
  FileReference,
  FailideLeslaadimiseTeenusApiFactory as fileUploadAPI,
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialApplicationAPI,
  TaotluseAndmeteTeenusApiFactory as applicationAPI
} from "../../../../../../api_client/medre_api";
import { saveFilesWithAlerts } from "../../../../../Component/FileDropzone/UploadFile";
import { ApplicationCertificateDraft } from "../../../../../Dto/ApplicationCertificate/ApplicationCertificate";
import { displayAlert } from "../../../../../Util/AlertUtil";
import { AlertType } from "../../../../../Dto/Alert/AlertItem";

interface Props {
  toggleAutomaticEducationLoad: () => void;
  setIsSaveAllowed: (isAllowed: boolean) => void;
}

export const ManualEducationView = ({
  toggleAutomaticEducationLoad,
  setIsSaveAllowed
}: Props) => {
  const dispatch = useDispatch();
  const [showSwitchConfirmationModal, setShowSwitchConfirmationModal] =
    useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [areFilesPresent, setAreFilesPresent] = useState(false);
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft
  );
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const allowedFileExtensions = ["png", "jpg", "jpeg", "doc", "docx", "odt", "pdf", "asice", "ddoc", "bdoc"];

  useEffect(() => {
    setIsSaveAllowed(isFormValid && areFilesPresent);
  }, [setIsSaveAllowed, isFormValid, areFilesPresent]);

  const updateEducationInfo = useCallback(
    (info: EducationInfo) => {
      dispatch(
        applicationDraftActions.updateApplicationDraft("educationInfo", info)
      );
    },
    [dispatch]
  );

  const handleConfirmation = () => {
    setShowSwitchConfirmationModal(false);
    toggleAutomaticEducationLoad();
    if (
      applicationDraft.fileReferences &&
      applicationDraft.fileReferences?.length > 0
    ) {
      fileUploadAPI(undefined, getBaseUrl(), API)
        .deleteMultiple(applicationDraft.id as string, {
          withCredentials: true
        })
        .then(() =>
          dispatch(
            applicationDraftActions.updateApplicationDraft("fileReferences", [])
          )
        );
    }
  };

  const handleFileSave = (files: File[], fileType: FileObjectType) => {
    saveFilesWithAlerts(
      files,
      fileType,
      applicationDraft.id!,
      dispatch,
      updateFiles
    );
  };

  const updateFiles = (updateFileReferences: FileReference[]) => {
    if (!applicationDraft.fileReferences) {
      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "fileReferences",
          updateFileReferences
        )
      );
    } else {
      let tempFiles = [
        ...applicationDraft.fileReferences,
        ...updateFileReferences
      ];
      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "fileReferences",
          tempFiles
        )
      );
    }
  };

  const deleteFunction = isPortal ?
    (id: string, fileType: FileObjectType, fileId: string) => applicationAPI(undefined, getBaseUrl(), API)
      .deleteApplicationDraftFile(id, fileType, fileId, { withCredentials: true }) :
    (id: string, fileType: FileObjectType, fileId: string) => officialApplicationAPI(undefined, getBaseUrl(), API)
      .deleteOfficialApplicationDraftFile(id,fileType,fileId, { withCredentials: true });

  const handleFileDelete = (fileName: string, fileType: FileObjectType) => {
    let toDelete: FileReference | undefined =
      applicationDraft?.fileReferences?.filter((file) => {
        return file.fileName === fileName && file.fileObjectType === fileType;
      })[0];

    if (toDelete && toDelete?.minioFileName) {
      deleteFunction(applicationDraft.id!, toDelete.fileObjectType!, toDelete.minioFileName)
        .then(() => {
          handleFileDeletion(toDelete?.minioFileName!);
          displayAlert("fileDeleteSuccessful", AlertType.Info, dispatch);
        })
        .catch(() => displayAlert("fileDeleteFailure", AlertType.Danger, dispatch));
    }
  };

  const handleFileDeletion = (minioFileName: string) => {
    dispatch(applicationDraftActions.removeFileReference(minioFileName));
  };

  return (
    <div className="education-content">
      <EducationEditFields
        occupationCode={
          applicationDraft.occupation?.code || applicationDraft.speciality?.code
        }
        educationInfo={applicationDraft.educationInfo}
        updateEducationInfo={updateEducationInfo}
        updateIsFormValid={setIsFormValid}
        isTHTDetailsPage={false}
      />
      <AttachmentFields
        updateAreFilesValid={setAreFilesPresent}
        objectId={applicationDraft.id!}
        fileReferences={applicationDraft.fileReferences || []}
        saveFiles={handleFileSave}
        handleFileDelete={handleFileDelete}
        allowedFileExtensions={allowedFileExtensions}
      />
      {isSwitchToAutomaticEducationPossible(
        isPortal,
        (applicationDraft as ApplicationCertificateDraft).personalData
      ) && (
        <div className="mt-4">
          <FormattedMessage
            id="manualEducationView.addEducationAutomatically"
            defaultMessage="Eestis omandatud hariduse info saate <button>läbi EHIS-i päringu.</button>"
            values={{
              button: () => (
                <LinkButton
                  id="setAutomatically"
                  className="p-0"
                  onClick={() => setShowSwitchConfirmationModal(true)}
                />
              )
            }}
          />
        </div>
      )}
      <SwitchToAutomaticEducationViewModal
        isOpen={showSwitchConfirmationModal}
        onClose={() => setShowSwitchConfirmationModal(false)}
        onSave={() => handleConfirmation()}
      />
    </div>
  );
};
