import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AttachmentContainer } from "./AttachmentContainer";
import { displayAlert } from "../../../../../../Util/AlertUtil";
import { AlertType } from "../../../../../../Dto/Alert/AlertItem";
import { areFileNamesUnique } from "../../../../../../Util/FileUtils";
import {
  FileObjectType,
  FileReference
} from "../../../../../../../api_client/medre_api";

interface Props {
  updateAreFilesValid: (isValid: boolean) => void;
  objectId: string;
  fileReferences: FileReference[];
  saveFiles: (files: File[], fileType: FileObjectType) => void;
  handleFileDelete: (fileName: string, fileType: FileObjectType) => void;
  allowedFileExtensions?: string[];
}

export const AttachmentFields = ({
  updateAreFilesValid,
  fileReferences,
  saveFiles,
  handleFileDelete,
  allowedFileExtensions
}: Props) => {
  const [activeAttachmentType, setActiveAttachmentType] =
    useState<FileObjectType | null>(null);
  const [isProceedingDiplomaPresent, setIsProceedingDiplomaPresent] =
    useState(false);

  const dispatch = useDispatch();

  const filterFiles = (type: FileObjectType) => {
    return fileReferences?.filter(
      (fileReference) => fileReference.fileObjectType === type
    );
  };

  useEffect(() => {
    updateAreFilesValid(isProceedingDiplomaPresent);
  }, [updateAreFilesValid, isProceedingDiplomaPresent]);

  const validateAndSave = (files: File[], objectType: FileObjectType) => {
    if (fileReferences && !areFileNamesUnique(files, fileReferences)) {
      displayAlert("fileNameNotUnique", AlertType.Danger, dispatch);
      return;
    }
    saveFiles(files, objectType);
  };

  return (
    <div className="application-fields">
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.ProceedingDiploma)
        }
        isActive={activeAttachmentType === FileObjectType.ProceedingDiploma}
        type={FileObjectType.ProceedingDiploma}
        fileReferences={filterFiles(FileObjectType.ProceedingDiploma)}
        updateAreFilesValid={setIsProceedingDiplomaPresent}
        maxFilesCount={50}
        saveFiles={(files) =>
          validateAndSave(files, FileObjectType.ProceedingDiploma)
        }
        handleFileDelete={(fileName) =>
          handleFileDelete(fileName, FileObjectType.ProceedingDiploma)
        }
        allowedFileTypes={allowedFileExtensions}
      />
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.ProceedingAcademicReport)
        }
        isActive={
          activeAttachmentType === FileObjectType.ProceedingAcademicReport
        }
        type={FileObjectType.ProceedingAcademicReport}
        fileReferences={filterFiles(FileObjectType.ProceedingAcademicReport)}
        maxFilesCount={50}
        saveFiles={(files) =>
          validateAndSave(files, FileObjectType.ProceedingAcademicReport)
        }
        handleFileDelete={(fileName) =>
          handleFileDelete(fileName, FileObjectType.ProceedingAcademicReport)
        }
        allowedFileTypes={allowedFileExtensions}
      />
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.ProceedingAdditionalDocument)
        }
        isActive={
          activeAttachmentType === FileObjectType.ProceedingAdditionalDocument
        }
        type={FileObjectType.ProceedingAdditionalDocument}
        fileReferences={filterFiles(
          FileObjectType.ProceedingAdditionalDocument
        )}
        maxFilesCount={50}
        saveFiles={(files) =>
          validateAndSave(files, FileObjectType.ProceedingAdditionalDocument)
        }
        handleFileDelete={(fileName) =>
          handleFileDelete(
            fileName,
            FileObjectType.ProceedingAdditionalDocument
          )
        }
        allowedFileTypes={allowedFileExtensions}
      />
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.ProceedingNameChangeDocument)
        }
        isActive={
          activeAttachmentType === FileObjectType.ProceedingNameChangeDocument
        }
        type={FileObjectType.ProceedingNameChangeDocument}
        fileReferences={filterFiles(
          FileObjectType.ProceedingNameChangeDocument
        )}
        maxFilesCount={50}
        saveFiles={(files) =>
          validateAndSave(files, FileObjectType.ProceedingNameChangeDocument)
        }
        handleFileDelete={(fileName) =>
          handleFileDelete(
            fileName,
            FileObjectType.ProceedingNameChangeDocument
          )
        }
        allowedFileTypes={allowedFileExtensions}
      />
    </div>
  );
};
