import React, { useState } from "react";
import { Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosError } from "axios";
import { FormattedMessage } from "react-intl";
import NavigationPrompt from "react-router-navigation-prompt";

import "../../../../Component/Button/Button.scss";
import { RootState } from "../../../../rootReducer";
import { API, getBaseUrl } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { OfficialApplicationStep } from "../ApplicationStep";
import {
  ApplicationDiscardConfirmationModal
} from "../../../Shared/Application/Footer/ApplicationDiscardConfirmationModal";
import { ApplicationSaveConfirmationModal } from "../../../Shared/Application/Footer/ApplicationSaveConfirmationModal";
import { ApplicationCertificateDraft } from "../../../../Dto/ApplicationCertificate/ApplicationCertificate";
import {
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI,
  ApplicationDraft,
  KasutajaAndmeteTeenusAmetnikeleApiFactory as officialUserDataAPI,
  Person,
  PersonalData,
  TaotluseAndmeteTeenusApiFactory as appDataAPI
} from "../../../../../api_client/medre_api";

interface Props {
  currentStep: number;
  steps: JSX.Element[];
}

export const OfficialApplicationFooter = ({ currentStep, steps }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft as ApplicationCertificateDraft
  );
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [shouldShowNavigationPrompt, setShouldShowNavigationPrompt] =
    useState(true);

  const saveButtonClass = () =>
    currentStep >= 2
      ? ""
      : currentStep === 0
      ? "disabled"
      : applicationDraft.saveAllowed
      ? ""
      : "disabled";

  const updateDraftRequest = () =>
    officialAppDataAPI(
      undefined,
      getBaseUrl(),
      API
    ).saveOfficialApplicationDraft(
      applicationDraft.id as string,
      applicationDraft,
      {
        withCredentials: true
      }
    );

  const createDraftRequest = () =>
    officialAppDataAPI(
      undefined,
      getBaseUrl(),
      API
    ).saveOfficialApplicationDraft1(applicationDraft, {
      withCredentials: true
    });

  const saveDraft = () => createOrUpdateDraft(currentStep, true);

  const handleSave = () => {
    switch (currentStep) {
      case OfficialApplicationStep.PERSONAL_DATA:
        if (!applicationDraft.id && !applicationDraft.personalData?.id) {
          return createNewUser(currentStep, true);
        }
        updateUserContactData();
        if (applicationDraft.personalData?.foreignIdCode) {
          savePersonalData();
        }
        saveDraft();
        break;
      default:
        saveDraft();
    }
  };

  const handleNavigation = (newStep: number) =>
    createOrUpdateDraft(newStep, false);

  const createOrUpdateDraft = (newStep: number, draftSave: boolean) => {
    if (newStep === OfficialApplicationStep.PERSONAL_DATA) {
      dispatch(
        applicationDraftActions.updateApplicationDraft("currentStep", newStep)
      );
      if (draftSave) {
        setConfirmationModalIsOpen(false);
      }
      setShouldShowNavigationPrompt(false);
      return;
    }
    let request = applicationDraft.id
      ? updateDraftRequest()
      : createDraftRequest();
    request
      .then((response) => {
        if (draftSave) {
          return handleDraftSaveSuccess();
        }
        const draft: ApplicationCertificateDraft | ApplicationDraft =
          applicationDraft.id ? applicationDraft : response.data;
        draft.currentStep = newStep;
        if (applicationDraft.personalData) {
          (draft as ApplicationCertificateDraft).personalData =
            applicationDraft.personalData;
          (draft as ApplicationCertificateDraft).contactInfo =
            applicationDraft.contactInfo;
          (draft as ApplicationCertificateDraft).saveAllowed = false;
        }
        dispatch(applicationDraftActions.setApplicationDraft(draft));
      })
      .catch((error: AxiosError) => {
        if (draftSave) {
          return handleDraftSaveError(error);
        }
        let responseData = error.response?.data;
        let alertMessage;
        if (responseData.error === "ApplicationDraftSaveException") {
          alertMessage = <AlertMessage id={responseData.message} />;
        } else {
          alertMessage = <AlertMessage id="applicationSaveFailed" />;
        }
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      })
      .finally(() => {
        if (draftSave) {
          setConfirmationModalIsOpen(false);
        }
      });
  };

  const createNewUser = (newStep: number, draftSave: boolean) => {
    const person: Person = {};
    const personalData = applicationDraft.personalData;
    person.idCode = personalData?.idCode;
    person.citizenship = personalData?.citizenship;
    person.foreignIdCodeCountryId = personalData?.foreignIdCodeCountryId;
    person.dateOfBirth = personalData?.dateOfBirth;
    person.firstName = personalData?.firstName;
    person.lastName = personalData?.lastName;
    person.foreigner = personalData?.foreigner;
    person.foreignIdCode = personalData?.foreignIdCode;
    person.gender = personalData?.gender;
    person.contactInfo = applicationDraft.contactInfo;

    officialUserDataAPI(undefined, getBaseUrl(), API)
      .createPerson({
        person,
        isGenerateIdCode: !personalData?.idCode && !personalData?.foreignIdCode
      })
      .then((response) => {
        let personResponse = response.data.person;
        applicationDraft.personId = personResponse!.id;
        applicationDraft.personalData = { ...personResponse } as PersonalData;
        applicationDraft.personalData.idCode = personalData?.idCode;
        createOrUpdateDraft(newStep, draftSave);
      })
      .catch(() => {
        const alertMessage = (
          <FormattedMessage
            id="contactInfoEditFooter.unsuccessfulSave"
            defaultMessage="Uue kasutaja loomine ebaõnnestus"
          />
        );
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  const updateUserContactData = () =>
    officialUserDataAPI(undefined, getBaseUrl(), API).updatePersonContactInfo(
      applicationDraft.personalData?.id!,
      applicationDraft.contactInfo!,
      {
        withCredentials: true
      }
    );

  const savePersonalData = () =>
    officialUserDataAPI(undefined, getBaseUrl(), API).updatePerson2(
      applicationDraft.personId!,
      applicationDraft.personalData!,
      {
        withCredentials: true
      }
    );

  const handleDraftSaveSuccess = () => {
    const alertMessage = <AlertMessage id="applicationSaveSuccess" />;
    const alert = { id: 0, type: AlertType.Success, message: alertMessage };
    dispatch(alertActions.addAlert(alert));
    setShouldShowNavigationPrompt(false);
    history.push("/applications");
  };

  const handleDraftSaveError = (error: AxiosError) => {
    let responseData = error.response?.data;
    let alertMessage;
    if (responseData.error === "ApplicationDraftSaveException") {
      alertMessage = <AlertMessage id={responseData.message} />;
    } else {
      alertMessage = <AlertMessage id="applicationSaveFailed" />;
    }
    const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
    dispatch(alertActions.addAlert(alert));
  };

  const navigate = (changeStep: number) => {
    switch (currentStep) {
      case OfficialApplicationStep.PERSONAL_DATA:
        if (!applicationDraft.id && !applicationDraft.personalData?.id) {
          return createNewUser(currentStep + changeStep, false);
        }
        updateUserContactData();
        if (applicationDraft.personalData?.foreignIdCode) {
          savePersonalData();
        }
        handleNavigation(currentStep + changeStep);
        break;
      case OfficialApplicationStep.SPECIALITY_OCCUPATION:
        handleNavigation(currentStep + changeStep);
        break;
      case OfficialApplicationStep.OVERVIEW:
        if (changeStep === 1) {
          appDataAPI(undefined, getBaseUrl(), API)
            .createApplication(applicationDraft.id!, {
              withCredentials: true
            })
            .then((response) => {
              setShouldShowNavigationPrompt(false);
              history.push(`/applications/${response.data.id}`);
            });
        } else {
          handleNavigation(currentStep + changeStep);
        }
        break;
      default:
        handleNavigation(currentStep + changeStep);
    }
  };

  const renderBackwardButton = () => {
    if (currentStep === OfficialApplicationStep.PERSONAL_DATA) {
      return (
        <SecondaryFormattedButton
          id="discard"
          onClick={() => handleDiscardModalOpenChange(true)}
        />
      );
    } else if (
      currentStep === OfficialApplicationStep.SPECIALITY_OCCUPATION ||
      currentStep === OfficialApplicationStep.EDUCATION_INFO
    ) {
      return (
        <SecondaryFormattedButton id="back" onClick={() => navigate(-1)} />
      );
    } else {
      return (
        <SecondaryFormattedButton
          id="back"
          disabled={!applicationDraft.saveAllowed}
          onClick={() => navigate(-1)}
        />
      );
    }
  };

  const handleDiscardModalOpenChange = (isOpen: boolean) => {
    setDiscardModalIsOpen(isOpen);
    setShouldShowNavigationPrompt(!isOpen);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModalIsOpen(!confirmationModalIsOpen);
  };

  const getModalMessage = () => (
    <FormattedMessage
      id="applicationSaveConfirmationModal.bodyOfficial"
      defaultMessage="Taotluse seis salvestatakse."
    />
  );

  return (
    <>
      <Row className="ml-0 mr-0 application-footer-buttons">
        {renderBackwardButton()}
        <LinkButton
          id="saveDraft"
          className={saveButtonClass()}
          onClick={() => setConfirmationModalIsOpen(true)}
        />
        <PrimaryFormattedButton
          className="ml-4"
          id="forward"
          disabled={!applicationDraft.saveAllowed}
          onClick={() => navigate(1)}
          {...(currentStep === steps.length - 1 && {
            id: "sendApplication"
          })}
        />
      </Row>
      <ApplicationDiscardConfirmationModal
        isOpen={discardModalIsOpen}
        onClose={() => handleDiscardModalOpenChange(!discardModalIsOpen)}
        onDiscard={() => history.push("/applications")}
      />
      <ApplicationSaveConfirmationModal
        isOpen={confirmationModalIsOpen}
        onClose={toggleConfirmationModal}
        onSave={handleSave}
        modalMessage={getModalMessage()}
      />
      <NavigationPrompt when={shouldShowNavigationPrompt}>
        {({ onConfirm, onCancel }) => (
          <ApplicationDiscardConfirmationModal
            isOpen={true}
            onClose={onCancel}
            onDiscard={onConfirm}
          />
        )}
      </NavigationPrompt>
    </>
  );
};
